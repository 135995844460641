import React, { useState } from 'react';
import axios from 'axios';
import './App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'

function App() {
  const [song, setSong] = useState(null);

  const getRandomSong = async () => {
    try {
      const response = await axios.get('https://spotsy-node.onrender.com/random-song');
      setSong(response.data);
    } catch (error) {
      console.error('Error fetching song:', error);
    }
  };

  return (
    <div className='homepage'>
      <div className='main'>
        <h1 className='title'>sPotsy for <FontAwesomeIcon icon={faSpotify}/></h1>
        {song ? (
          <div className='songDiv'>
            <img className='coverImg'
              src={song.albumCover}
              alt={`${song.name} album cover`}
            />
            <div className='vertSongDiv'>
              <p className='songTitle'>
                <strong>{song.name}</strong> by {song.artist}
              </p>
              <a className='songLink' href={song.url} target="_blank" rel="noreferrer">
                Listen on Spotify
              </a>
            </div>
            
          </div>
        ) : (
          <div className='textDescription'>
            <p>Welcome to sPotsy, your gateway to discovering music effortlessly!</p>
            <p>Click the button below to generate a random Spotify song and explore new tunes you might not have found otherwise.</p>
            <p>Who knows? Your next favorite track could be just one click away!</p>
          </div>
        )}
        <button className='randomButton' onClick={getRandomSong}>
          <span>Get Random Song</span>
        </button>
      </div>
      <p className='copyright'>&copy; 2024 <a className='copyrightLink' href='potsy.co'>sPotsy</a>. All rights reserved.</p>
    </div>
  );
}

export default App;